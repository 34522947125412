<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">도감설명 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        style="width:300px;"
                        class="shrink"
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- 페이지 -->
                    <v-text-field
                        style="width:80px;"
                        class="shrink no-spinners"
                        outlined
                        dense
                        label="페이지"
                        hide-details
                        type="number"
                        v-model="page"
                        @change="options.page = parseInt(page)"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="paginatedItems"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td>{{item.en}} ({{item.kr}})</td>
                            <td class="py-2">
                                <div
                                    class="d-flex flex-wrap"
                                >
                                    <div v-if="item.red" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('red', item.red)}}</div>
                                    <div v-if="item.green" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('green', item.green)}}</div>
                                    <div v-if="item.blue" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('blue', item.blue)}}</div>
                                    <div v-if="item.yellow" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('yellow', item.yellow)}}</div>
                                    <div v-if="item.gold" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('gold', item.gold)}}</div>
                                    <div v-if="item.silver" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('silver', item.silver)}}</div>
                                    <div v-if="item.crystal" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('crystal', item.crystal)}}</div>
                                    <div v-if="item.ruby" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('ruby', item.ruby)}}</div>
                                    <div v-if="item.sapphire" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('sapphire', item.sapphire)}}</div>
                                    <div v-if="item.emerald" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('emerald', item.emerald)}}</div>
                                    <div v-if="item.fire_red" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('fire_red', item.fire_red)}}</div>
                                    <div v-if="item.leaf_green" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('leaf_green', item.leaf_green)}}</div>
                                    <div v-if="item.diamond" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('diamond', item.diamond)}}</div>
                                    <div v-if="item.perl" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('perl', item.perl)}}</div>
                                    <div v-if="item.platinum" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('platinum', item.platinum)}}</div>
                                    <div v-if="item.heart_gold" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('heart_gold', item.heart_gold)}}</div>
                                    <div v-if="item.soul_silver" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('soul_silver', item.soul_silver)}}</div>
                                    <div v-if="item.black" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('black', item.black)}}</div>
                                    <div v-if="item.white" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('white', item.white)}}</div>
                                    <div v-if="item.black_2" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('black_2', item.black_2)}}</div>
                                    <div v-if="item.white_2" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('white_2', item.white_2)}}</div>
                                    <div v-if="item.x" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('x', item.x)}}</div>
                                    <div v-if="item.y" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('y', item.y)}}</div>
                                    <div v-if="item.omega_ruby" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('omega_ruby', item.omega_ruby)}}</div>
                                    <div v-if="item.alpha_sapphire" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('alpha_sapphire', item.alpha_sapphire)}}</div>
                                    <div v-if="item.sun" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('sun', item.sun)}}</div>
                                    <div v-if="item.moon" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('moon', item.moon)}}</div>
                                    <div v-if="item.ultra_sun" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('ultra_sun', item.ultra_sun)}}</div>
                                    <div v-if="item.ultra_moon" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('ultra_moon', item.ultra_moon)}}</div>
                                    <div v-if="item.lets_go_pikachu" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('lets_go_pikachu', item.lets_go_pikachu)}}</div>
                                    <div v-if="item.lets_go_eevee" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('lets_go_eevee', item.lets_go_eevee)}}</div>
                                    <div v-if="item.sword" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('sword', item.sword)}}</div>
                                    <div v-if="item.shield" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('shield', item.shield)}}</div>
                                    <div v-if="item.brilliant_diamond" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('brilliant_diamond', item.brilliant_diamond)}}</div>
                                    <div v-if="item.shining_perl" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('shining_perl', item.shining_perl)}}</div>
                                    <div v-if="item.legends_arceus" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('legends_arceus', item.legends_arceus)}}</div>
                                    <div v-if="item.scarlet" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('scarlet', item.scarlet)}}</div>
                                    <div v-if="item.violet" class="mr-1 mt-2px mb-2px px-2 alter_sky white--text py-1 rounded-lg text-center" style="min-width:32px;">{{getVersionName('violet', item.violet)}}</div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:footer>
                        <v-pagination
                            style="border-top:1px solid #eee;"
                            class="pt-3 pb-2"
                            v-model="options.page"
                            :length="pageCount"
                            :total-visible="12"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="pa-2 mt-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.en}}({{selectedItem.kr}})
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-6"
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <!-- <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn> -->
            </v-card>

            <!-- dialog -->
            <v-sheet>
                <!-- 수정하기 -->
                <v-dialog v-model="dialog.update" width="auto" :key="componentKey">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:200px;">버전</th>
                                    <th style="width:500px;">설명</th>
                                </tr>
                                <tr v-for="(value, key) in select_list.version" :key="key">
                                    <td>{{value}}</td>
                                    <td>
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedItem[key]"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="updateSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                수정하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.update=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: '', width: '45px' },
            {text: '포켓몬 이름', value: 'en', width: '20%' },
            {text: '도감설명 버전', value: 'version', width: '80%' },
        ],

        select_list: {
            search_type: [
                "영어 포켓몬 이름",
                "한글 포켓몬 이름"
            ],

            status: [
                "활성",
                "비활성",
            ],

            version: {
                red: "적",
                green: "녹", 
                blue: "청",
                yellow: "피카츄",
                gold: "금",
                silver: "은",
                crystal: "크리스탈", 
                ruby: "루비",
                sapphire: "사파이어",
                emerald: "에메랄드",
                fire_red: "파이어레드",
                leaf_green: "리프그린",
                diamond: "다이아몬드",
                perl: "펄",
                platinum: "플라티나",
                heart_gold: "하트골드",
                soul_silver: "소울실버",
                black: "블랙",
                white: "화이트", 
                black_2: "블랙2",
                white_2: "화이트2",
                x: "x",
                y: "y",
                omega_ruby: "오메가루비",
                alpha_sapphire: "알파사파이어",
                sun: "썬",
                moon: "문",
                ultra_sun: "울트라썬",
                ultra_moon: "울트라문",
                lets_go_pikachu: "레츠고피카츄",
                lets_go_eevee: "레츠고이브이",
                sword: "소드",
                shield: "실드",
                brilliant_diamond: "브릴리언트다이아몬드",
                shining_perl: "샤이닝펄",
                legends_arceus: "레전즈아르세우스",
                scarlet: "스칼렛",
                violet: "바이올렛"
            }
        },

        search_type: "영어 포켓몬 이름",
        keyword: "",

        allItems: [], // API에서 가져온 모든 아이템
		options: {
			page: 1,
			itemsPerPage: 10
		},
		totalItems: 0,
        page: 0,

        selected: false,

        dialog: {
            update:false,
            create:false
        },

        create: {
            en: "",
            kr: "",
        },

        selectedItem: {
            red: "",
            green: "",
            blue: "",
            yellow: "",
            gold: "",
            silver: "",
            crystal: "",
            ruby: "",
            sapphire: "",
            emerald: "",
            fire_red: "",
            leaf_green: "",
            diamond: "",
            perl: "",
            platinum: "",
            heart_gold: "",
            soul_silver: "",
            black: "",
            white: "",
            black_2: "",
            white_2: "",
            x: "",
            y: "",
            omega_ruby: "",
            alpha_sapphire: "",
            sun: "",
            moon: "",
            ultra_sun: "",
            ultra_moon: "",
            lets_go_pikachu: "",
            lets_go_eevee: "",
            sword: "",
            shield: "",
            brilliant_diamond: "",
            shining_perl: "",
            legends_arceus: "",
            scarlet: "",
            violet: ""
        },

        componentKey: 0,
    }),

    computed: {
		pageCount() {
			return Math.ceil(this.totalItems / this.options.itemsPerPage)
		},

		paginatedItems() {
			const start = (this.options.page - 1) * this.options.itemsPerPage
			const end = start + this.options.itemsPerPage
            this.page = this.options.page
			return this.allItems.slice(start, end)
		}
	},

    mounted(){
        // 목록 불러오기
        this.search()

        window.addEventListener('keydown', this.handleKeyDown)
    },

    methods: {
        getVersionName(key, value) {
            if (!value) return ''
            return this.select_list.version[key] || key
        },

        handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'q') {
                this.updateOpen()
            }
        },

        // 목록 불러오기
        search(){
            this.$http.post('/api/docs_explain/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            })
            .then((res) => {
                this.allItems = res.data
                this.totalItems = this.allItems.length
                console.log(this.allItems)
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.red = item.red
            this.selectedItem.green = item.green
            this.selectedItem.blue = item.blue
            this.selectedItem.yellow = item.yellow
            this.selectedItem.gold = item.gold
            this.selectedItem.silver = item.silver
            this.selectedItem.crystal = item.crystal
            this.selectedItem.ruby = item.ruby
            this.selectedItem.sapphire = item.sapphire
            this.selectedItem.emerald = item.emerald
            this.selectedItem.fire_red = item.fire_red
            this.selectedItem.leaf_green = item.leaf_green
            this.selectedItem.diamond = item.diamond
            this.selectedItem.perl = item.perl
            this.selectedItem.platinum = item.platinum
            this.selectedItem.heart_gold = item.heart_gold
            this.selectedItem.soul_silver = item.soul_silver
            this.selectedItem.black = item.black
            this.selectedItem.white = item.white
            this.selectedItem.black_2 = item.black_2
            this.selectedItem.white_2 = item.white_2
            this.selectedItem.x = item.x
            this.selectedItem.y = item.y
            this.selectedItem.omega_ruby = item.omega_ruby
            this.selectedItem.alpha_sapphire = item.alpha_sapphire
            this.selectedItem.sun = item.sun
            this.selectedItem.moon = item.moon
            this.selectedItem.ultra_sun = item.ultra_sun
            this.selectedItem.ultra_moon = item.ultra_moon
            this.selectedItem.lets_go_pikachu = item.lets_go_pikachu
            this.selectedItem.lets_go_eevee = item.lets_go_eevee
            this.selectedItem.sword = item.sword
            this.selectedItem.shield = item.shield
            this.selectedItem.brilliant_diamond = item.brilliant_diamond
            this.selectedItem.shining_perl = item.shining_perl
            this.selectedItem.legends_arceus = item.legends_arceus
            this.selectedItem.scarlet = item.scarlet
            this.selectedItem.violet = item.violet
            this.componentKey += 1
        },

        // 포켓몬 이름 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 포켓몬 이름이 없습니다.")
            }
        },

        // 포켓몬 이름 수정 Submit
        updateSubmit(){
            this.$http.post('/api/docs_explain/update', {
                params: {
                    pokemon_id: this.selectedItem.id,
                    red: this.selectedItem.red,
                    green: this.selectedItem.green,
                    blue: this.selectedItem.blue,
                    yellow: this.selectedItem.yellow,
                    gold: this.selectedItem.gold,
                    silver: this.selectedItem.silver,
                    crystal: this.selectedItem.crystal,
                    ruby: this.selectedItem.ruby,
                    sapphire: this.selectedItem.sapphire,
                    emerald: this.selectedItem.emerald,
                    fire_red: this.selectedItem.fire_red,
                    leaf_green: this.selectedItem.leaf_green,
                    diamond: this.selectedItem.diamond,
                    perl: this.selectedItem.perl,
                    platinum: this.selectedItem.platinum,
                    heart_gold: this.selectedItem.heart_gold,
                    soul_silver: this.selectedItem.soul_silver,
                    black: this.selectedItem.black,
                    white: this.selectedItem.white,
                    black_2: this.selectedItem.black_2,
                    white_2: this.selectedItem.white_2,
                    x: this.selectedItem.x,
                    y: this.selectedItem.y,
                    omega_ruby: this.selectedItem.omega_ruby,
                    alpha_sapphire: this.selectedItem.alpha_sapphire,
                    sun: this.selectedItem.sun,
                    moon: this.selectedItem.moon,
                    ultra_sun: this.selectedItem.ultra_sun,
                    ultra_moon: this.selectedItem.ultra_moon,
                    lets_go_pikachu: this.selectedItem.lets_go_pikachu,
                    lets_go_eevee: this.selectedItem.lets_go_eevee,
                    sword: this.selectedItem.sword,
                    shield: this.selectedItem.shield,
                    brilliant_diamond: this.selectedItem.brilliant_diamond,
                    shining_perl: this.selectedItem.shining_perl,
                    legends_arceus: this.selectedItem.legends_arceus,
                    scarlet: this.selectedItem.scarlet,
                    violet: this.selectedItem.violet
                }
            }).then((res) => {
                this.dialog.update = false
                this.selected = false
                this.selectedItem = {}
                this.search()
            })
        }
    }
}
</script>

<style scoped>
.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
/* v-text-field, v-autocomplete > 입력값 글자 가운데 정렬 */
::v-deep .v-text-field .v-input__slot input {
    text-align: left;
}
</style>

<style scoped>
/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#99d1e5 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .v-text-field .v-input__slot { 
    height: 40px;
}

/* v-text-field, v-select, v-autocomplete > ▼ 아이콘 (v-text-field 한정 append-icon) */
::v-deep .v-text-field .v-input__icon--append .v-icon {
    padding-bottom: 11px;
}

</style>