<template>
    <v-sheet
        :class="!$vuetify.breakpoint.mobile? 'rounded-12':''"
        :style="!$vuetify.breakpoint.mobile?
            {
                width: '362px',
                minHeight: '780px',
                border: '1px solid #ddd',
                paddingBottom: '10px',
                boxShadow: '0 0 6px 0 #e1e1e1'
            }
            : 
            {
                height: '100%'
            }
        "
    >
        <!-- # 1줄 -->
        <div
            class="d-flex align-center docs_pokemon_autocomplete"
            :style="{
                height: '64px',
                marginTop: isMobileBrowser ? '-2px' : '-3px',
                marginBottom: '-2px'
            }"
        >
            <!-- # 포켓몬 이름 -->
            <!-- v-if="$vuetify.breakpoint.mobile" -->
            <v-autocomplete
                ref="pokemonAutocomplete"
                class="rounded-lg shrink"
                style="width:195px; margin-left:10px;"
                placeholder="포켓몬 선택"
                persistent-placeholder
                dense
                hide-details
                item-value="en"
                item-text="kr"
                :items="pokemon_list"
                v-model="pokemon.name"
                outlined
                :menu-props="{
                    offsetY: true,
                    maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                }"
                @change="loadPokemon()"
                @keydown.enter.native="handleEnter"
            ></v-autocomplete>

            <v-spacer></v-spacer>

            <!-- 검 -->
            <div
                class="ml-2 mr-3"
                style="width:24px; height:25px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab2() :''"
            >
                <v-img
                    contain
                    width="24"
                    height="25"
                    src="@/assets/icons/attacker.png"
                ></v-img>
            </div>

            <!-- 스피드 -->
            <div
                class="mr-1"
                style="width:14px; height:25px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab() : $store.commit('update_is_speed', true)"
            >
                <v-img
                    contain
                    src="@/assets/icons/speed_off.png"
                ></v-img>
            </div>

            <!-- 도감 -->
            <div
                class="ml-2 mr-4"
                style="width:25px; height:25px;"
                @click="$vuetify.breakpoint.mobile? toggleMobileTab3() : $store.commit('update_is_docs', !$store.state.is_docs)"
            >
                <v-img
                    contain
                    width="25"
                    src="@/assets/icons/technic.png"
                ></v-img>
            </div>
        </div>

        <div
            style="padding:0 10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:4px;"
                    class="version_group_select rounded-lg white--text text-center d-flex align-center justify-center"
                >
                    <div class="select-wrapper">
                        <v-select
                            class="shrink custom-select"
                            :items="version_group_list"
                            v-model="version_group"
                            hide-details
                            dense
                            item-value="value"
                            item-text="name_kr"
                            :menu-props="{
                                offsetY: true,
                                maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                            }"
                        ></v-select>
                    </div>
                </div>
            </div>
        </div>

        <div
            style="padding:0 10px; margin-top:10px;"
        >
            <div
                class="rounded-lg"
            >
                <div
                    style="height:25px; background:#434343; font-size:12px; padding-bottom:4px;"
                    class="version_group_select rounded-t-lg white--text text-center d-flex align-center justify-center"
                >
                    <div class="select-wrapper">
                        <v-select
                            class="shrink custom-select"
                            :items="learn_method_list"
                            v-model="learn_method"
                            hide-details
                            dense
                            item-value="value"
                            item-text="name"
                            :menu-props="{
                                offsetY: true,
                                maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                            }"
                        ></v-select>
                    </div>
                </div>
                <table
                    v-if="!loading"
                    class="docs_table"
                >
                    <tr>
                        <th>{{computed_info_header}}</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr
                        v-for="move in computed_move_list"
                        :key="move.name"
                        style="text-align:center; border-bottom:1px solid #d9d9d9;"
                    >
                        <td style="width:70px;">
                            <div v-if="learn_method == 'tutor'">
                                <v-img
                                    contain
                                    height="16"
                                    style="width:40px; margin:0 auto;"
                                    :src="require(`@/assets/tutor/${version_group}.png`)"
                                ></v-img>
                            </div>
                            <div v-else>
                                {{move.info}}
                            </div>
                        </td>
                        <td style="width:105px;">{{translateMove(move.name)}}</td>
                        <td style="width:50px;">
                            <div
                                :style="caculate_type_color(move.type)" 
                                style="width:32px; height:16px; font-size:10px; color:white; margin:0 auto;margin-top:2px;"
                            >
                                {{move.type}}
                            </div>
                        </td>
                        <td style="width:40px;">{{move.damage_class}}</td>
                        <td style="width:40px;">{{move.power}}</td>
                        <td style="width:40px;">{{move.accuracy}}</td>
                    </tr>
                    <tr
                        v-if="computed_move_list.length == 0"
                    >
                        <td colspan="6" style="text-align:center;">
                            조건에 맞는 기술이 없습니다.
                        </td>
                    </tr>
                </table>
                <div
                    v-if="learn_method === 'egg'"
                    style="font-size:10px; text-align:center; margin-top:4px;"
                >
                    교배로 배우는 기술은 진화 전, 초기 형태의 포켓몬에서 정보를 알 수 있습니다.
                </div>
                <table
                    v-if="loading"
                    class="docs_table"
                >
                    <tr>
                        <th>{{computed_info_header}}</th>
                        <th>기술명</th>
                        <th>타입</th>
                        <th>분류</th>
                        <th>위력</th>
                        <th>명중률</th>
                    </tr>
                    <tr>
                        <td colspan="6" style="height:10px;">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="d-flex my-2 mx-auto"
                                width="2"
                                size="20"
                            ></v-progress-circular>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'

export default {
    props: [
        "docs_pokemon"
    ],

    data: () => ({
        // 타입 목록
        type_list: common.type_list,

        // 포켓몬 목록
        pokemon_list: [],

        // 버전 그룹 목록
        version_group_list: common.version_group_list,

        pokemon: {
            abilities: [],

            name: "",
            name_kr: "",
            name_jp: "",
            sprite_default: "",
            sprite_shiny: "",

            types: [],

            egg_groups: [],
            hatch_counter: 0,
            base_happiness: 0,
            total_experience: 0,
            color: "",

            height: 0,
            weight: 0,

            // 스탯
            stats: {
                // H
                hp: 0,

                // A
                attack: 0,

                // B
                defense: 0,

                // C
                special_attack: 0,

                // D
                special_defense: 0,

                // S
                speed: 0,
            },

            genera_type: "",
            capture_rate: 0,

            genetics: {
                female: 0,
                male: 0,
            },

            docs_explain: {},
        },

        type_counter_list: {
            노말: 1,
            불꽃: 1,
            물: 1,
            풀: 1,
            전기: 1,
            얼음: 1,
            격투: 1,
            독: 1,
            땅: 1,
            비행: 1,
            에스퍼: 1,
            벌레: 1,
            바위: 1,
            고스트: 1,
            드래곤: 1,
            악: 1,
            강철: 1,
            페어리: 1,
        },

        chain_list: [],

        loading: false,
        componentKey: 0,

        selected_ability: {},

        dialog: {
            ability: false,
        },

        name_addon: [
            "-galar",
            "-alola",
            "-hisui"
        ],

        group: {
            basic: true,
            stat: true,
            evolution: false,
            docs_explain: false,
            genetics: false,
        },

        version_group: "scarlet-violet",

        learn_method_list: [
            {
                value: 'level-up',
                name: '레벨업으로 배우는 기술'
            },
            {
                value: 'machine',
                name: '머신으로 배우는 기술'
            },
            {
                value: 'egg',
                name: '교배로 배우는 기술'
            },
            {
                value: 'tutor',
                name: '가르침으로 배우는 기술'
            },
            {
                value: 'event',
                name: '이벤트로 배우는 기술'
            },
        ],

        learn_method: "level-up",

        move_all_version_list: [],

        isMobileBrowser: false,
    }),

    computed: {
        computed_info_header() {
            if(this.learn_method === 'level-up') {
                return "레벨"
            }
            else if(this.learn_method === 'machine') {
                return "머신"
            }
            else if(this.learn_method === 'egg') {
                return "부모"
            }
            else if(this.learn_method === 'tutor') {
                return "NPC 버전"
            }
            else if(this.learn_method === 'event') {
                return "경로"
            }
        },

        computed_move_list() {
            // 먼저 현재 선택된 버전 그룹으로 필터링
            const versionFiltered = this.move_all_version_list.filter(move => 
                move.version_group === this.version_group
            )

            if(this.learn_method === 'level-up') {
                return versionFiltered.filter(move => move.learn === this.learn_method)
                .sort((a, b) => Number(a.info) - Number(b.info))
            }
            else if(this.learn_method === 'machine') {
                return versionFiltered.filter(move => move.learn === this.learn_method)
                .sort((a, b) => {
                    // TM과 HM 추출
                    const typeA = a.info.substring(0, 2).toLowerCase()
                    const typeB = b.info.substring(0, 2).toLowerCase()
                    
                    // 숫자 부분 추출
                    const numA = parseInt(a.info.replace(/[^0-9]/g, ''))
                    const numB = parseInt(b.info.replace(/[^0-9]/g, ''))

                    // TM이 HM보다 먼저 오도록
                    if (typeA !== typeB) {
                        return typeA === 'tm' ? -1 : 1
                    }
                    
                    // 같은 타입 내에서는 숫자로 정렬
                    return numA - numB
                })
            }
            else if(this.learn_method === 'egg') {
                return versionFiltered.filter(move => move.learn === this.learn_method)
            }
            else if(this.learn_method === 'tutor') {
                return versionFiltered.filter(move => move.learn === this.learn_method)
            }
            else if(this.learn_method === 'event') {
                const eventMethods = [
                    'stadium-surfing-pikachu',
                    'light-ball-egg',
                    'form-change',
                    'zygarde-cube'
                ]
                return versionFiltered.filter(move => eventMethods.includes(move.learn))
            }
        }
    },

    watch: {
        docs_pokemon(newVal) {
            if(this.pokemon.name != newVal) {
                this.pokemon.name = newVal
                this.loadPokemon()
            }
        },

        "$store.state.docs_pokemon"(newVal) {
            if(this.pokemon.name != newVal) {
                this.pokemon.name = newVal
                this.loadPokemon()
            }
        }
    },

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()

        // 기술 목록 불러오기
        this.loadMoveList()

        this.checkMobileBrowser()

        if(this.$store.state.docs_pokemon != this.pokemon.name) {
            this.pokemon.name = this.$store.state.docs_pokemon
            this.loadPokemon()
        }
    },

    beforeDestroy() {
        this.move_all_version_list = []
    },

    methods: {
        checkMobileBrowser() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera
            
            // 모바일 브라우저 체크를 위한 정규식
            const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

            this.isMobileBrowser = mobileRegex.test(userAgent.toLowerCase())
        },

        // 기술 목록 불러오기
        loadMoveList() {
            this.$http.post("/api/move/select")
            .then((res) => {
                this.move_list = res.data
            })
        },

        // 기술 번역
        translateMove(en) {
            const move = this.move_list.find(item => item.en === en)
            return move ? move.kr : en
        },

        handleEnter(e) {
            const currentValue = e.target.value
            if (currentValue) {
                // 한글 이름으로 영어 이름 찾기
                const pokemon = this.pokemon_list.find(p => p.kr === currentValue)
                if (pokemon) {
                    this.pokemon.name = pokemon.en // 영어 이름으로 설정
                    this.loadPokemon()
                    this.$refs.pokemonAutocomplete.blur()
                } else {
                    // 매칭되는 포켓몬이 없을 경우 직접 입력값 사용
                    this.pokemon.name = currentValue 
                    this.loadPokemon()
                    this.$refs.pokemonAutocomplete.blur()
                }
            }
        },

        // 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
            })
        },

        toggleMobileTab3() {
            if (this.$store.state.mobile_tab === 'docs_left') {
                this.$store.commit('update_mobile_tab', 'docs_right')
            } else {
                this.$store.commit('update_mobile_tab', 'docs_left')
            }
        },

        toggleMobileTab2() {
            if (this.$store.state.mobile_tab === 'speed_defender') {
                this.$store.commit('update_mobile_tab', 'speed_attacker')
            } else {
                this.$store.commit('update_mobile_tab', 'attacker')
            }
        },

        toggleMobileTab() {
            if (this.$store.state.mobile_tab === 'speed_defender') {
                this.$store.commit('update_mobile_tab', 'defender')
            } else {
                this.$store.commit('update_mobile_tab', 'speed_defender')
            }
        },

        caculate_type_color(type) {
            const typeColors = {
                노말: '#949495',
                불꽃: '#e56c3e',
                물: '#5185c5',
                풀: '#66a945',
                전기: '#fbb917',
                얼음: '#6dc8eb',
                격투: '#e09c40',
                독: '#735198',
                땅: '#9c7743',
                비행: '#a2c3e7',
                에스퍼: '#dd6b7b',
                벌레: '#9fa244',
                바위: '#bfb889',
                고스트: '#684870',
                드래곤: '#535ca8',
                악: '#4c4948',
                강철: '#69a9c7',
                페어리: '#dab4d4'
            }
            return { backgroundColor: typeColors[type] || '#ffffff' } // 기본값은 흰색
        },

        // 포켓몬(pokemon) 선택 불러오기 (최적화 전)
        // loadPokemon: _.debounce(async function(e) {
        //     this.learn_method = 'level-up'
        //     this.loading = true
        //     this.$store.state.docs_pokemon = this.pokemon.name
        //     await this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.pokemon.name)
        //     .then(async(res) => {
        //         // moves 데이터 기본 구성
        //         const moves = res.data.moves.flatMap(moveData => {
        //             const levelUpDetail = moveData.version_group_details.find(
        //                 detail => detail.move_learn_method.name === 'level-up'
        //             )
                    
        //             // 특수 학습 방법에 대한 한글 매핑
        //             const specialLearnMethods = {
        //                 'stadium-surfing-pikachu': '스타디움',
        //                 'light-ball-egg': '전기구슬 교배',
        //                 'form-change': '폼체인지',
        //                 'zygarde-cube': '지가르데코어'
        //             }
                    
        //             return moveData.version_group_details.map(detail => {
        //                 let info = '-'
        //                 const learnMethod = detail.move_learn_method.name

        //                 // level-up인 경우
        //                 if (learnMethod === 'level-up') {
        //                     info = levelUpDetail.level_learned_at
        //                 } 
        //                 // 특수 학습 방법인 경우
        //                 else if (specialLearnMethods[learnMethod]) {
        //                     info = specialLearnMethods[learnMethod]
        //                 }

        //                 return {
        //                     info,
        //                     url: moveData.move.url,
        //                     name: moveData.move.name,
        //                     learn: learnMethod,
        //                     version_group: detail.version_group.name
        //                 }
        //             })
        //         })
                
        //         // 각 기술별 상세 정보 추가
        //         const formattedMoves = await Promise.all(
        //             moves.map(async (move) => {
        //                 try {
        //                     const moveDetail = await this.$http.get(move.url)

        //                     // 타입의 한글 이름 가져오기
        //                     const typeResponse = await this.$http.get(moveDetail.data.type.url)
        //                     const koreanType = typeResponse.data.names.find(name => name.language.name === 'ko')?.name || moveDetail.data.type.name

        //                     // damage_class URL에서 마지막 숫자 추출
        //                     const damageClassId = moveDetail.data.damage_class.url.split('/').filter(Boolean).pop()
        //                     let damageClass
        //                     switch(damageClassId) {
        //                         case '1':
        //                             damageClass = '변화'
        //                             break
        //                         case '2':
        //                             damageClass = '물리'
        //                             break
        //                         case '3':
        //                             damageClass = '특수'
        //                             break
        //                         default:
        //                             damageClass = moveDetail.data.damage_class.name
        //                     }

        //                     let moveInfo = {
        //                         ...move,
        //                         type: koreanType,
        //                         damage_class: damageClass,
        //                         power: moveDetail.data.power,
        //                         accuracy: moveDetail.data.accuracy
        //                     }

        //                     // machine인 경우 추가 처리
        //                     if (move.learn === 'machine') {
        //                         const matchingMachine = moveDetail.data.machines.find(
        //                             m => m.version_group.name === move.version_group
        //                         )
        //                         if (matchingMachine) {
        //                             const machineResponse = await this.$http.get(matchingMachine.machine.url)
        //                             moveInfo.info = machineResponse.data.item.name
        //                         }
        //                     }

        //                     return moveInfo
        //                 } catch (error) {
        //                     console.error(`Error fetching move details for ${move.name}:`, error)
        //                     return move
        //                 }
        //             })
        //         )
                
        //         console.log("formattedMoves", formattedMoves)

        //         this.move_all_version_list = formattedMoves
        //         this.loading = false
        //     })
        // }, 100, { leading: true, trailing: false }),


        // 포켓몬(pokemon) 선택 불러오기 (최적화 후)
        loadPokemon: _.debounce(async function(e) {
            // 함수 시작시 메모리 정리
            this.move_all_version_list = []
            
            this.learn_method = 'level-up'
            this.loading = true
            this.$store.state.docs_pokemon = this.pokemon.name

            // 여기서 변수 선언
            let moves = []
            let formattedMoves = []
            
            try {
                const res = await this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.pokemon.name)
                
                // moves 데이터 기본 구성
                moves = res.data.moves.flatMap(moveData => {
                    const levelUpDetail = moveData.version_group_details.find(
                        detail => detail.move_learn_method.name === 'level-up'
                    )
                    
                    // 특수 학습 방법에 대한 한글 매핑
                    const specialLearnMethods = {
                        'stadium-surfing-pikachu': '스타디움',
                        'light-ball-egg': '전기구슬 교배',
                        'form-change': '폼체인지',
                        'zygarde-cube': '지가르데코어'
                    }
                    
                    return moveData.version_group_details.map(detail => {
                        let info = '-'
                        const learnMethod = detail.move_learn_method.name

                        // level-up인 경우
                        if (learnMethod === 'level-up') {
                            info = levelUpDetail.level_learned_at
                        } 
                        // 특수 학습 방법인 경우
                        else if (specialLearnMethods[learnMethod]) {
                            info = specialLearnMethods[learnMethod]
                        }

                        return {
                            info,
                            url: moveData.move.url,
                            name: moveData.move.name,
                            learn: learnMethod,
                            version_group: detail.version_group.name
                        }
                    })
                })
                
                // 각 기술별 상세 정보 추가
                formattedMoves = await Promise.all(
                    [...new Map(moves.map(item => [item.url, item])).values()].map(async (move) => {
                        try {
                            const moveDetail = await this.$http.get(move.url)

                            // 타입의 한글 이름 가져오기
                            const typeResponse = await this.$http.get(moveDetail.data.type.url)
                            const koreanType = typeResponse.data.names.find(name => name.language.name === 'ko')?.name || moveDetail.data.type.name

                            // damage_class URL에서 마지막 숫자 추출
                            const damageClassId = moveDetail.data.damage_class.url.split('/').filter(Boolean).pop()
                            let damageClass
                            switch(damageClassId) {
                                case '1':
                                    damageClass = '변화'
                                    break
                                case '2':
                                    damageClass = '물리'
                                    break
                                case '3':
                                    damageClass = '특수'
                                    break
                                default:
                                    damageClass = moveDetail.data.damage_class.name
                            }

                            let moveInfo = {
                                ...move,
                                type: koreanType,
                                damage_class: damageClass,
                                power: moveDetail.data.power,
                                accuracy: moveDetail.data.accuracy
                            }

                            // machine인 경우 추가 처리
                            if (move.learn === 'machine') {
                                const matchingMachine = moveDetail.data.machines.find(
                                    m => m.version_group.name === move.version_group
                                )
                                if (matchingMachine) {
                                    const machineResponse = await this.$http.get(matchingMachine.machine.url)
                                    moveInfo.info = machineResponse.data.item.name
                                }
                            }

                            return moveInfo
                        } catch (error) {
                            console.error(`Error fetching move details for ${move.name}:`, error)
                            return move
                        }
                    })
                )

                // 원본 moves 배열의 정보를 formattedMoves의 결과로 매핑
                this.move_all_version_list = moves.map(move => {
                    const formattedMove = formattedMoves.find(fm => fm.url === move.url)
                    return {
                        ...formattedMove,
                        info: move.info,
                        learn: move.learn,
                        version_group: move.version_group
                    }
                })

            } catch (error) {
                console.error('Error loading pokemon:', error)
            } finally {
                this.loading = false
                
                // 사용이 끝난 변수들 명시적으로 정리
                moves = null
                formattedMoves = null
            }
        }, 100, { leading: true, trailing: false })
    }
}
</script>
<style scoped>
.docs_table {
    width: calc(100% - 2px);
    margin:0 1px;
    height:100%;
    border-collapse: collapse;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #d9d9d9;
}

::v-deep .docs_table th {
    height: 25px;
    font-weight: 500;
    padding-bottom: 2px;
    background:#D9D9D9 !important;
    font-size: 10px;
}

::v-deep .docs_table td {
    border-right: 1px solid #d9d9d9;
    padding: 4px;
    font-size: 10px;
    font-weight: 400;
}

::v-deep .docs_table td:first-child {
    width: 120px;
}

::v-deep .docs_table td:last-child {
    border-right: none;
}

.docs_table2 {
    width: calc(100% - 2px);
    margin:0 1px;
    height:100%;
    border-collapse: collapse;
    border-radius: 8px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #d9d9d9;
}

::v-deep .docs_table2 th {
    height: 25px;
    font-weight: 500;
    padding-bottom: 2px;
    background:#D9D9D9 !important;
    font-size: 10px;
}

::v-deep .docs_table2 td {
    border-right: 1px solid #d9d9d9;
    padding: 4px;
    height: 23px;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
}

::v-deep .docs_table2 td:first-child {
    width: 120px;
}

::v-deep .docs_table2 td:last-child {
    border-right: none;
}
</style>

<style scoped>
::v-deep .version_group_select .v-text-field .v-input__slot {
    min-height: 25px !important;
    height: 25px !important;
}

::v-deep .version_group_select .v-text-field .v-input__icon--append .v-icon {
    font-size: 20px;
    margin-top: 3px;
    color: white;
}

/* v-select > 선택값 글자 */
::v-deep .version_group_select .v-select__selection, .v-select__selection--comma, .v-select.v-text-field input {
    color: white !important;
    font-size:12px !important;
}

/* v-select > focus시 언더바 없애기 (1) */
::v-deep .version_group_select .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}

/* v-select > focus시 언더바 없애기 (2) */
::v-deep .version_group_select .v-input__slot::before,
::v-deep .version_group_select .v-input__slot::after {
    border: none !important;
}

/* v-select > focus시 언더바 없애기 (3) */
::v-deep .version_group_select .v-select.v-text-field input {
    display: none;
}

/* v-select > 화살표와 같이 가운데 정렬 (1) */
.select-wrapper {
    width: fit-content;
    margin: 0 auto;
    max-width: 90%;
}

/* v-select > 화살표와 같이 가운데 정렬 (2) */
::v-deep .custom-select {
    width: auto !important;
}

/* v-select > 화살표와 같이 가운데 정렬 (3) */
::v-deep .custom-select .v-select__selection {
    margin-left: 8px;
    white-space: nowrap;
    overflow: visible;
}

/* v-select > 화살표와 같이 가운데 정렬 (4) */
::v-deep .custom-select .v-input__append-inner {
    /* 글자와 화살표 사이 간격 */
    margin-left: 0px;

    margin-right: 8px;
}

/* v-select > 화살표와 같이 가운데 정렬 (5) */
::v-deep .custom-select .v-input__slot {
    margin-bottom: 3px;
    padding: 0 !important;
    min-width: fit-content;
}

/* v-select > 화살표와 같이 가운데 정렬 (6) */
::v-deep .v-menu__content {
    max-width: none !important;
}

/* v-select > 화살표와 같이 가운데 정렬 (7) */
::v-deep .v-list-item__title {
    white-space: nowrap;
}


/* .docs_pokemon_autocomplete {
    width: 195px;
} */

::v-deep .docs_pokemon_autocomplete .v-text-field .v-input__slot {
    min-height: 40px !important;
    height: 40px !important;
}

::v-deep .docs_pokemon_autocomplete .v-text-field .v-input__icon--append .v-icon {
    font-size: 26px;
    margin-top: -2px;
}
</style>