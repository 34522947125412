<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">특성 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        style="width:300px;"
                        class="shrink"
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- 페이지 -->
                    <v-text-field
                        style="width:80px;"
                        class="shrink no-spinners"
                        outlined
                        dense
                        label="페이지"
                        hide-details
                        type="number"
                        v-model="page"
                        @change="options.page = parseInt(page)"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="paginatedItems"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td>{{item.name_en}}</td>
                            <td>{{item.name_kr}}</td>
                            <td>{{item.text_kr}}</td>
                        </tr>
                    </template>
                    <template v-slot:footer>
                        <v-pagination
                            style="border-top:1px solid #eee;"
                            class="pt-3 pb-2"
                            v-model="options.page"
                            :length="pageCount"
                            :total-visible="12"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="pa-2 mt-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.en}}({{selectedItem.kr}})
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-6"
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <!-- <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn> -->
            </v-card>

            <!-- dialog -->
            <v-sheet>
                <!-- 수정하기 -->
                <v-dialog v-model="dialog.update" width="auto">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:54px;"></th>
                                    <th style="width:300px;">영어 특성 이름</th>
                                    <th style="width:300px;">한글 특성 이름</th>
                                    <th style="width:300px;">한글 특성 설명</th>
                                </tr>
                                <tr>
                                    <td style="width:54px;">기존</td>
                                    <td style="width:500px;">{{selectedItem.name_en}}</td>
                                    <td style="width:300px;">{{selectedItem.name_kr}}</td>
                                    <td style="width:300px;">{{selectedItem.text_kr}}</td>
                                </tr>
                                <tr v-if="selectedAfter">
                                    <td style="width:54px;">변경</td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.name_en"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.name_kr"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.text_kr"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="updateSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                수정하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.update=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>

                <!-- 추가하기 -->
                <v-dialog v-model="dialog.create" width="auto">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:54px;"></th>
                                    <th style="width:300px;">영어 특성 이름</th>
                                    <th style="width:300px;">한글 특성 이름</th>
                                    <th style="width:300px;">한글 특성 설명</th>
                                </tr>
                                <tr v-if="create">
                                    <td style="width:54px;">추가</td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="create.name_en"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="create.name_kr"
                                        ></v-text-field>
                                    </td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="create.text_kr"
                                        ></v-text-field>
                                    </td>
                                    </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="createSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                추가하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.create=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: '', width: '45px' },
            {text: '영어 특성 이름', value: 'name_en', width: '12%' },
            {text: '한글 특성 이름', value: 'name_kr', width: '12%' },
            {text: '한글 특성 설명', value: 'text_kr', width: '76%' },
        ],

        select_list: {
            search_type: [
                "영어 특성 이름",
                "한글 특성 이름",
                "한글 특성 설명"
            ],

            status: [
                "활성",
                "비활성",
            ],
        },

        search_type: "영어 특성 이름",
        keyword: "",

        allItems: [], // API에서 가져온 모든 아이템
		options: {
			page: 1,
			itemsPerPage: 10
		},
		totalItems: 0,
        page: 0,

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        dialog: {
            update:false,
            create:false
        },

        create: {
            name_en: "",
            name_kr: "",
            text_kr: "",
        }
    }),

    computed: {
		pageCount() {
			return Math.ceil(this.totalItems / this.options.itemsPerPage)
		},
		paginatedItems() {
			const start = (this.options.page - 1) * this.options.itemsPerPage
			const end = start + this.options.itemsPerPage
            this.page = this.options.page
			return this.allItems.slice(start, end)
		}
	},

    mounted(){
        // 목록 불러오기
        this.search()

        window.addEventListener('keydown', this.handleKeyDown)
    },

    methods: {
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'q') {
                this.updateOpen()
            }
        },

        // 목록 불러오기
        search(){
            this.$http.post('/api/ability/select/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            })
            .then((res) => {
                this.allItems = res.data
                this.totalItems = this.allItems.length
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.name_en = item.name_en
            this.selectedItem.name_kr = item.name_kr
            this.selectedItem.text_kr = item.text_kr
            this.selectedAfter.id = item.id
            this.selectedAfter.name_en = item.name_en
            this.selectedAfter.name_kr = item.name_kr
            this.selectedAfter.text_kr = item.text_kr
        },

        // 포켓몬 이름 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 포켓몬 이름이 없습니다.")
            }
        },

        // 포켓몬 이름 수정 Submit
        updateSubmit(){
            this.$http.post('/api/ability/update', {
                params: {
                    before: this.selectedItem,
                    after: this.selectedAfter
                }
            }).then((res) => {
                // alert("해당 포켓몬 이름 정보가 수정되었습니다.")
                this.dialog.update = false
                this.selected = false
                this.selectedItem = {}
                this.selectedAfter = {}
                this.search()
            })
        },

        // 포켓몬 이름 추가 Submit
        createSubmit(){
            this.$http.post('/api/ability/create', {
                params: {
                    name_en: this.create.name_en,
                    name_kr: this.create.name_kr,
                    text_kr: this.create.text_kr
                }
            }).then((res) => {
                alert("특성이 추가되었습니다.")
                this.dialog.create = false
                this.create = {}
                this.search()
            })
        },

        // 상태 변경하기
        update_status(item){
            this.$http.post('/api/ability/update/status', {
                params: {
                    status: item.status,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    // alert("활성/비활성 상태가 변경되었습니다.")
                }
            })
        },

        // 특성 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 특성이 없습니다.")
            }else{
                if(confirm('정말 해당 특성을 삭제하시겠습니까?')){
                    this.$http.post('/api/ability/delete', {
                        params: {
                            id: this.selectedItem.id,
                        }
                    }).then((res) => {
                        alert("해당 특성이 삭제되었습니다.")
                        this.search()
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
/* v-text-field, v-autocomplete > 입력값 글자 가운데 정렬 */
::v-deep .v-text-field .v-input__slot input {
    text-align: left;
}
</style>

<style scoped>
/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#99d1e5 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

/* v-text-field, v-select, v-autocomplete > 전체 높이 지정 */
::v-deep .v-text-field .v-input__slot { 
    height: 40px;
}

/* v-text-field, v-select, v-autocomplete > ▼ 아이콘 (v-text-field 한정 append-icon) */
::v-deep .v-text-field .v-input__icon--append .v-icon {
    padding-bottom: 11px;
}

</style>